
import { defineComponent, ref } from 'vue';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import moment from 'moment';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainer view',
  components: {},
  data() {
    return {
      moment: '' as any,
      api_url: '',
      trainer: {},
      traineeInfo: {},
      empDistricts: [],
      load: false,
      id: this.$route.params.id,
      tabIndex: ref(0),
    };
  },
  async created() {
    this.moment = moment;
    this.load = true;
    await this.getEmployeeDetails();
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute('data-tab-index'));
    },
    async getEmployeeDetails() {
      this.load = true;
      await ApiService.get('trainee/show/' + `${this.id}`)
        .then((response) => {
          this.load = false;
          this.traineeInfo = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
